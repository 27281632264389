import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENV) && !window.Cypress) {
    Sentry.init({
        Vue,
        dsn: 'https://fac5bcda37d04449b14cee0f051b8758@o527262.ingest.sentry.io/5818443',
        environment: ENV || 'production',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.vueIntegration({
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
